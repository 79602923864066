<template>
  <container>
    <div
      v-if="this.$route.meta.currentStep && this.$route.meta.currentStep > 1"
    >
      <div class="flex flex-col lg:flex-row">
        <div class="w-full lg:w-2/3">
          <box hasCounter>
            <div class="mb-6">
              <steps-indicator />
            </div>
            <div class="p-6 md:px-8 md:py-12">
              <router-view v-on:update-user-data="onSubmit" />
            </div>
          </box>
        </div>
        <div class="w-full hidden md:block mt-8 lg:pl-12 lg:w-1/3">
          <sidebar-summary />
        </div>
      </div>
    </div>
    <div v-else>
      <box>
        <div class="p-6 md:px-8 md:py-12">
          <router-view v-on:update-user-data="onSubmit" />
        </div>
      </box>
    </div>
  </container>
</template>

<script>
import Box from "./Ui/Box.vue";
import Container from "./Ui/Container.vue";
import SidebarSummary from "./ZodiacFormComponents/SidebarSummary.vue";
import StepsIndicator from "./ZodiacFormComponents/StepsIndicator.vue";
export default {
  name: "ZodiacForm",
  components: {
    SidebarSummary,
    StepsIndicator,
    Container,
    Box,
  },

  methods: {
    onSubmit(payload) {
      for (const [key, value] of Object.entries(payload)) {
        this.$store.commit("updateUserData", { [key]: value });
      }

      if (this.completedStep < this.$route.meta.currentStep) {
        console.log("Updating completed step");
        this.$store.commit("updateCompletedStep", this.$route.meta.currentStep);
      }

      this.$router.push(
        this.$route.meta.currentStep === this.totalSteps
          ? this.$t("router.results.path")
          : `/step-${this.$route.meta.currentStep + 1}`
      );
    },
  },

  computed: {
    showStepsIndicator() {
      return this.$route.name.includes("Step");
    },
    completedStep() {
      return this.$store.state.completedStep;
    },
    totalSteps() {
      return this.$store.state.totalSteps;
    },
  },
};
</script>
