<template>
  <div
    class="bg-white text-gray-900 shadow-2xl"
    :class="{ 'border-t-4 border-red-500': !hasCounter }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "Box",
  props: {
    hasCounter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
