<template>
  <div>
    <div v-if="!isOnPaymentPage">
      <div
        class="text-center font-headings text-2xl mb-12 "
        v-if="this.userData.zodiacSign"
      >
        <router-link v-if="isEditable" to="/">
          <inline-svg
            :src="signSelected"
            class="fill-current w-32 h-32 mb-3 mx-auto text-yellow-500"
          />
          <span v-text="this.userData.zodiacSign" />
        </router-link>
        <div v-else>
          <inline-svg
            :src="signSelected"
            class="fill-current w-32 h-32 mb-3 mx-auto text-yellow-500"
          />
          <span>{{ this.userData.zodiacSign }}</span>
        </div>
      </div>
    </div>
    <ul class="pl-6 border-l-2 border-yellow-500 ">
      <li
        class="mb-1 flex items-center"
        v-if="this.userData.zodiacSign && isOnPaymentPage"
      >
        <span>
          <span v-text="i18nLabel($t('label.zodiac'))" />
          <span v-text="this.userData.zodiacSign" />
        </span>

        <router-link
          v-if="isEditable"
          to="/"
          class="hover:text-yellow-500 p-2 bg-black bg-opacity-25 hover:bg-opacity-30 flex items-center justify-center rounded-full ml-2"
        >
          <inline-svg
            src="/img/icon-edit.svg"
            class="fill-current w-3 h-3 flex-shrink-0"
          />
        </router-link>
      </li>
      <li class="mb-1 flex items-center" v-if="this.birthDate">
        <span>
          <span v-text="i18nLabel($t('label.birthday_short'))" />
          <span v-text="birthDate" />
        </span>

        <router-link
          v-if="isEditable"
          to="/step-2"
          class="hover:text-yellow-500 p-2 bg-black bg-opacity-25 hover:bg-opacity-30 flex items-center justify-center rounded-full ml-2"
        >
          <inline-svg
            src="/img/icon-edit.svg"
            class="fill-current w-3 h-3 flex-shrink-0"
          />
        </router-link>
      </li>
      <li class="mb-1 flex items-center" v-if="this.userData.birthTime">
        <span>
          <span v-text="i18nLabel($t('label.time_of_birth_short'))" />
          <span v-text="this.userData.birthTime" />
        </span>

        <router-link
          v-if="isEditable"
          to="/step-5"
          class="hover:text-yellow-500 p-2 bg-black bg-opacity-25 hover:bg-opacity-30 flex items-center justify-center rounded-full ml-2"
        >
          <inline-svg
            src="/img/icon-edit.svg"
            class="fill-current w-3 h-3 flex-shrink-0"
          />
        </router-link>
      </li>

      <li class="mb-1 flex items-center" v-if="this.userData.cityOfBirth">
        <span>
          <span v-text="i18nLabel($t('label.city_of_birth_short'))" />
          <span v-text="this.userData.cityOfBirth" />
        </span>

        <router-link
          v-if="isEditable"
          to="/step-6"
          class="hover:text-yellow-500 p-2 bg-black bg-opacity-25 hover:bg-opacity-30 flex items-center justify-center rounded-full ml-2"
        >
          <inline-svg
            src="/img/icon-edit.svg"
            class="fill-current w-3 h-3 flex-shrink-0"
          />
        </router-link>
      </li>
      <li class="mb-1 flex items-center" v-if="this.userData.postalCode">
        <span>
          <span v-text="i18nLabel($t('label.postal_code'))" />
          <span v-text="this.userData.postalCode" />
        </span>

        <router-link
          v-if="isEditable"
          to="/step-6"
          class="hover:text-yellow-500 p-2 bg-black bg-opacity-25 hover:bg-opacity-30 flex items-center justify-center rounded-full ml-2"
        >
          <inline-svg
            src="/img/icon-edit.svg"
            class="fill-current w-3 h-3 flex-shrink-0"
          />
        </router-link>
      </li>
      <li class="mb-1 flex items-center" v-if="this.userData.gender">
        <span>
          <span v-text="i18nLabel($t('label.gender'))" />
          <span v-text="this.userData.gender" />
        </span>

        <router-link
          v-if="isEditable"
          to="/step-6"
          class="hover:text-yellow-500 p-2 bg-black bg-opacity-25 hover:bg-opacity-30 flex items-center justify-center rounded-full ml-2"
        >
          <inline-svg
            src="/img/icon-edit.svg"
            class="fill-current w-3 h-3 flex-shrink-0"
          />
        </router-link>
      </li>
      <li class="mb-1 flex items-center" v-if="this.userData.name">
        <span>
          <span v-text="i18nLabel($t('label.name'))" />
          <span v-text="this.userData.name" />
        </span>

        <router-link
          v-if="isEditable"
          to="/step-6"
          class="hover:text-yellow-500 p-2 bg-black bg-opacity-25 hover:bg-opacity-30 flex items-center justify-center rounded-full ml-2"
        >
          <inline-svg
            src="/img/icon-edit.svg"
            class="fill-current w-3 h-3 flex-shrink-0"
          />
        </router-link>
      </li>
      <li class="mb-1 flex items-center" v-if="this.userData.email">
        <span class="whitespace-nowrap flex gap-1">
          <span v-text="i18nLabel($t('label.email_short'))" />
          <span
            v-text="this.userData.email"
            class="overflow-hidden overflow-ellipsis w-32 block"
          />
        </span>
        <router-link
          v-if="isEditable"
          to="/step-6"
          class="hover:text-yellow-500 p-2 bg-black bg-opacity-25 hover:bg-opacity-30 flex items-center justify-center rounded-full ml-2"
        >
          <inline-svg
            src="/img/icon-edit.svg"
            class="fill-current w-3 h-3 flex-shrink-0"
          />
        </router-link>
      </li>
    </ul>

    <div
      class="mt-8"
      v-if="
        offerSelected && this.$route.name === this.$t('router.payment.name')
      "
    >
      <div class="flex items-center">
        <span v-html="'<sup>*</sup> ' + i18nLabel($t('label.selectedOffer'))" />
        <router-link
          v-if="isEditable"
          :to="$t('router.offer.path')"
          class="text-yellow-500 inline-block px-3 py-1 border border-yellow-500 rounded-lg ml-2"
        >
          {{ offerSelected.name }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarSummary",
  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
    isOnPaymentPage: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    userData() {
      return this.$store.state.userData;
    },
    birthDate() {
      if (
        this.userData.birthDay &&
        this.userData.birthMonth &&
        this.userData.birthYear
      ) {
        return `${this.userData.birthDay} ${this.userData.birthMonth} ${this.userData.birthYear}`;
      } else return null;
    },
    signSelected() {
      let sign = this.$store.state.zodiacSigns.filter(
        (sign) => sign.name === this.userData.zodiacSign
      );
      return sign[0].thumb;
    },
    offerSelected() {
      return this.$store.state.offerSelected;
    },
  },
};
</script>
