<template>
  <div class="h-1 bg-gray-500 w-full">
    <span
      class="block h-1 bg-yellow-500"
      :data-scale="scalePercentage"
      v-bind:style="{ width: scalePercentage, transition: 'width .3s linear' }"
    />
  </div>
</template>

<script>
export default {
  name: "StepsIndicator",

  computed: {
    currentStep() {
      return this.$route.meta.currentStep;
    },
    totalSteps() {
      return this.$store.state.totalSteps;
    },
    scalePercentage() {
      return (this.currentStep / this.totalSteps) * 100 + "%";
    },
  },
};
</script>
