<template>
  <div v-if="isSidebar">
    <div
      :class="isSidebar ? 'mb-2' : 'mb-4'"
      v-for="item in services"
      :key="item.id"
    >
      <services-list-card :isSidebar="isSidebar" :item="item" />
    </div>
  </div>
  <div v-else>
    <div class="flex flex-wrap -mx-6">
      <div
        class="px-6 w-full sm:w-1/2 md:w-1/3 mb-12"
        v-for="item in services"
        :key="item.id"
      >
        <services-list-card :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
import ServicesListCard from "./ServicesListCard.vue";
export default {
  components: { ServicesListCard },
  name: "ServicesList",
  props: {
    isSidebar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    services() {
      return this.$store.state.services;
    },
  },
};
</script>

<style></style>
