<template>
  <div
    class="fixed inset-x-0 bottom-0 z-30 bg-black bg-opacity-80 p-6 pt-8 md:hidden"
  >
    <h2
      class="text-center font-headings text-xl mb-4"
      v-text="$t('title.get_started')"
    />
    <router-link
      to="/step-1"
      class="bg-red-500 p-4 text-white w-full flex items-center justify-center text-center font-bold rounded transition-colors active:bg-red-600"
    >
      <span v-text="$t('button.get_started')" />
      <inline-svg
        src="/img/icon-right.svg"
        class="fill-current ml-3 animate-arrow"
      />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "CtaModalStart",
};
</script>
