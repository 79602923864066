<template>
  <div class="relative max-w-4xl">
    <h1
      class="text-4xl lg:text-5xl font-headings"
      style="line-height: 1.3; font-size: clamp(1.3rem, 8vw, 2.7rem);"
      v-text="$t('page.homepage.title')"
    />
    <!-- <span /> -->
    <!-- <inline-svg
        src="/img/arrow-begin.svg"
        class="fill-current absolute right-0 hidden lg:block"
      /> -->
    <!-- </h1> -->
    <ul class="mt-12 mb-12 text-lg">
      <li class="flex items-center mb-3">
        <inline-svg
          src="/img/icon-check.svg"
          class="w-5 h-5 mr-3 flex-shrink-0 fill-current"
        />
        <span v-text="$t('page.homepage.feature_1')" />
      </li>
      <li class="flex items-center mb-3">
        <inline-svg
          src="/img/icon-check.svg"
          class="w-5 h-5 mr-3 flex-shrink-0 fill-current"
        />
        <span v-text="$t('page.homepage.feature_2')" />
      </li>
      <li class="flex items-center mb-3">
        <inline-svg
          src="/img/icon-check.svg"
          class="w-5 h-5 mr-3 flex-shrink-0 fill-current"
        />
        <span v-text="$t('page.homepage.feature_3')" />
      </li>
      <li class="flex items-center mb-3">
        <inline-svg
          src="/img/icon-check.svg"
          class="w-5 h-5 mr-3 flex-shrink-0 fill-current"
        />
        <span v-text="$t('page.homepage.feature_4')" />
      </li>
    </ul>
    <p
      class="text-xs opacity-60 lg:pr-24 mb-2"
      v-text="$t('misc.private_information')"
    />
    <div
      v-html="$t('misc.subscription_notion')"
      class="text-xs opacity-60 lg:pr-24"
    />
    <transition appear appear-active-class="show-cta-animation">
      <cta-modal-start />
    </transition>
  </div>
</template>

<script>
import CtaModalStart from "./CtaModalStart.vue";
export default {
  name: "Hero",
  components: { CtaModalStart },
};
</script>

<style></style>
