<template>
  <div
    :class="isSidebar ? 'flex -mx-2 items-center mb-5' : 'h-full flex flex-col'"
  >
    <div :class="isSidebar ? 'w-3/12 px-2' : 'mb-4'">
      <router-link
        :to="'/' + $t('router.reports.path') + '/' + item.path"
        :class="
          isSidebar
            ? 'relative h-0 pt-12 rounded overflow-hidden block'
            : 'relative h-0 pt-60 w-full overflow-hidden block'
        "
      >
        <picture>
          <source
            :srcset="
              `/img/services/service${item.id}.jpg.webp 1x, /img/services/service${item.id}@2x.jpg.webp 2x`
            "
            type="image/webp"
          />
          <source
            :srcset="
              `/img/services/service${item.id}.jpg 1x, /img/services/service${item.id}@2x.jpg 2x`
            "
            type="image/jpeg"
          />
          <img
            :src="`/img/services/service${item.id}.jpg`"
            :alt="item.title"
            loading="lazy"
            class="absolute inset-0 w-full h-full object-cover"
          />
        </picture>
      </router-link>
    </div>
    <div :class="isSidebar ? 'w-9/12 px-2' : 'flex flex-col flex-grow'">
      <router-link
        :to="'/' + $t('router.reports.path') + '/' + item.path"
        class="flex-grow"
      >
        <h3
          :class="isSidebar ? '' : 'text-center font-headings text-xl mb-2'"
          v-text="item.title"
        />
        <p
          v-if="!isSidebar"
          class="text-sm opacity-60"
          :class="!isSidebar ? 'text-center px-4' : ''"
          v-text="item.short_description"
        />
      </router-link>
      <p v-if="!isSidebar" class="text-center text-sm mt-4">
        <router-link
          :to="'/' + $t('router.reports.path') + '/' + item.path"
          class="btn border border-white border-opacity-20 hover:border-opacity-75"
          v-text="$t('button.view_more')"
        >
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesListCard",
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    isSidebar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
