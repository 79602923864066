<template>
  <div class="py-8">
    <container>
      <div class="flex flex-col md:flex-row gap-x-10">
        <div class="lg:pt-4 md:order-last">
          <hero />
        </div>
        <div class="hidden md:block md:-ml-5">
          <zodiac-form />
        </div>
      </div>
    </container>
    <div class="my-12 md:mb-24">
      <container>
        <testimonials-list />
      </container>
    </div>

    <container>
      <div class="mb-8 text-center">
        <h1
          class=" text-3xl lg:text-4xl font-headings"
          v-text="$t('title.services')"
        />
        <p>
          <inline-svg
            src="/img/icon-star.svg"
            class="fill-current w-8 h-8 mx-auto mt-2  mb-3  text-yellow-500"
          />
        </p>
      </div>
      <services-list />
    </container>
  </div>
</template>

<script>
import ServicesList from "../components/ServicesList.vue";
import TestimonialsList from "../components/TestimonialsList.vue";
import Hero from "../components/Hero.vue";
import ZodiacForm from "../components/ZodiacForm.vue";
import Container from "../components/Ui/Container.vue";

export default {
  name: "Guest",
  components: {
    Hero,
    TestimonialsList,
    ServicesList,
    ZodiacForm,
    Container,
  },
};
</script>
