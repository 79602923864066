<template>
  <div class="flex flex-col md:flex-row -mx-6">
    <div
      class="px-6 w-full md:w-1/2 mb-8 md:mb-0"
      v-for="item in testimonials"
      :key="item.name"
    >
      <testimonials-list-card :item="item" />
    </div>
  </div>
</template>

<script>
import TestimonialsListCard from "./TestimonialsListCard.vue";

export default {
  name: "TestimonialsList",
  components: { TestimonialsListCard },
  computed: {
    testimonials() {
      return this.$store.state.testimonials;
    },
  },
};
</script>
