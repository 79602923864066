<template>
  <div>
    <p>
      <inline-svg
        src="/img/icon-quote.svg"
        class="fill-current w-12 h-12 mb-8 text-yellow-500"
      />
    </p>

    <p class="mb-8" v-text="item.text" />
    <p class="opacity-50 text-sm">
      {{ item.name }}, {{ item.age }} <span v-text="$t('misc.years_old')" />,
      {{ item.location }}
    </p>
  </div>
</template>

<script>
export default {
  name: "TestimonialsListCard",
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
};
</script>
